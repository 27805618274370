import React from "react";

import styles from "./likes.module.css";
import likeIcon from "../../resources/like.svg";
import likeIconActive from "../../resources/likeActive.svg";

const Like = ({ isActive, likesCount }) => {
  return (
    <div className={styles.wrapper}>
      <img
        src={isActive ? likeIconActive : likeIcon}
        alt="Like"
        className={styles.icon}
      />
      <div className={styles.count}>{likesCount}</div>
      <div className={styles.label}>Likes</div>
    </div>
  );
};

export default Like;
