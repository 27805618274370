import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import qs from "qs";

export const getPins = createAsyncThunk(
  "pin/getPins",
  async ({ page, tags, searchText }, { rejectWithValue }) => {
    console.log("pin/getPins", { page, tags });
    try {
      const queryObj = {
        populate: {
          tags: {
            fields: ["name"],
          },
          image: {
            fields: ["name", "url"],
          },
        },
        fields: ["pintext", "pintextFull", "createdAt", "updatedAt"],
        filters: {
          tags: {
            id: {
              $in: tags,
            },
          },
          $or: [
            {
              pintext: {
                $containsi: searchText,
              },
            },
            {
              pintextFull: {
                $containsi: searchText,
              },
            },
          ],
        },
        pagination: {
          pageSize: 10,
          page: page ? page : 1,
          withCount: true,
        },
        sort: ["id:desc"],
        publicationState: "live",
      };
      const query = qs.stringify(queryObj, {
        encodeValuesOnly: true, // prettify URL
      });
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/pins?${query}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPinById = createAsyncThunk(
  "pin/getPinById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/pins/${id}?populate=*`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAuthorProfile = createAsyncThunk(
  "pin/getAuthorProfile",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/author-profile?populate=*`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPrivacyPolicy = createAsyncThunk(
  "pin/getPrivacyPolicy",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/privacy-policy?populate=*`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTags = createAsyncThunk(
  "pin/getTags",
  async (page, { rejectWithValue }) => {
    try {
      const query = qs.stringify(
        {
          populate: {
            pins: {
              fields: ["title"],
            },
          },
          fields: ["name"],
          publicationState: "live",
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/tags?${query}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSiteLikes = createAsyncThunk(
  "pin/getSiteLikes",
  async (page, { rejectWithValue }) => {
    try {
      const query = qs.stringify(
        {
          fields: ["liked", "email"],
          publicationState: "live",
          filters: {
            liked: {
              $eq: true,
            },
          },
          pagination: {
            page: 1,
            pageSize: 1,
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/site-likes?${query}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUserLikeStatus = createAsyncThunk(
  "pin/getUserLikeStatus",
  async (email, { rejectWithValue }) => {
    try {
      const query = qs.stringify(
        {
          fields: ["liked", "email"],
          publicationState: "live",
          filters: {
            email: {
              $eqi: email,
            },
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/site-likes?${query}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createLikedStatus = createAsyncThunk(
  "pin/createLikedStatus",
  async ({ email, status }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/site-likes`,
        {
          data: {
            email,
            liked: status,
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateLikedStatus = createAsyncThunk(
  "pin/updateLikedStatus",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/site-likes/${id}`,
        {
          data: {
            liked: status,
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const slice = createSlice({
  name: "pin",
  initialState: {
    pins: [],
    pinsPagination: null,
    searchText: "",
    searchPinsResult: [],
    searchPinsPagination: null,
    searchDone: false,
    pinDetails: null,
    tags: [],
    tagsSelected: [],
    tagActiveId: null,
    tagPins: [],
    tagPinsPagination: null,
    authorProfile: null,
    privacyPolicy: null,
    pagination: null,
    currentPage: 1,
    isLoading: false,
    isSuccess: false,
    likesCount: 0,
    likesLoading: false,
    userLikedStatus: null,
    updatingLikeStatus: false,
    errorMessage: "",
  },
  reducers: {
    resetSearch: (state) => {
      state.searchDone = false;
      state.searchPinsResult = [];
    },
    selectTag: (state, { payload }) => {
      state.tagsSelected.push(payload);
      state.pins = [];
      state.pinsPagination = null;
    },
    deSelectTag: (state, { payload }) => {
      state.tagsSelected = state.tagsSelected.filter(
        (tagId) => tagId !== payload
      );
      state.pins = [];
      state.pinsPagination = null;
    },
    resetTagSelection: (state) => {
      state.tagsSelected = [];
      state.pins = [];
      state.pinsPagination = null;
    },
    doSearch: (state, { payload }) => {
      state.searchText = payload;
      state.pins = [];
      state.pinsPagination = null;
    },
    doResetSearch: (state) => {
      state.searchText = "";
      state.pins = [];
      state.pinsPagination = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPins.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPins.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.pins = state.pins.concat(action.payload?.data);
        state.pinsPagination = action.payload?.meta?.pagination;
      })
      .addCase(getPins.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.pinsPagination = null;
        state.errorMessage = action.error.message;
      });

    builder
      .addCase(getPinById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPinById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.pinDetails = action.payload?.data;
      })
      .addCase(getPinById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = action.error.message;
      });

    builder
      .addCase(getAuthorProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAuthorProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.authorProfile = action.payload?.data;
      })
      .addCase(getAuthorProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = action.error.message;
      });

    builder
      .addCase(getPrivacyPolicy.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPrivacyPolicy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.privacyPolicy = action.payload?.data;
      })
      .addCase(getPrivacyPolicy.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = action.error.message;
      });

    builder
      .addCase(getTags.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tags = action.payload?.data;
      })
      .addCase(getTags.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = action.error.message;
      });

    builder
      .addCase(getSiteLikes.pending, (state, action) => {
        state.likesLoading = true;
      })
      .addCase(getSiteLikes.fulfilled, (state, action) => {
        state.likesLoading = false;
        state.likesCount = action.payload?.meta?.pagination?.total || 0;
      })
      .addCase(getSiteLikes.rejected, (state, action) => {
        state.likesLoading = false;
        state.errorMessage = action.error.message;
      });

    builder
      .addCase(getUserLikeStatus.pending, (state, action) => {
        state.likesLoading = true;
      })
      .addCase(getUserLikeStatus.fulfilled, (state, action) => {
        state.likesLoading = false;
        state.userLikedStatus = action.payload?.data[0];
      })
      .addCase(getUserLikeStatus.rejected, (state, action) => {
        state.likesLoading = false;
        state.errorMessage = action.error.message;
      });

    builder
      .addCase(createLikedStatus.pending, (state, action) => {
        state.updatingLikeStatus = true;
      })
      .addCase(createLikedStatus.fulfilled, (state, action) => {
        state.updatingLikeStatus = false;
        state.userLikedStatus = action.payload?.data;
        state.likesCount = state.likesCount + 1;
      })
      .addCase(createLikedStatus.rejected, (state, action) => {
        state.updatingLikeStatus = false;
        state.errorMessage = action.error.message;
      });

    builder
      .addCase(updateLikedStatus.pending, (state, action) => {
        state.updatingLikeStatus = true;
      })
      .addCase(updateLikedStatus.fulfilled, (state, action) => {
        state.updatingLikeStatus = false;
        state.userLikedStatus = action.payload?.data;
        state.likesCount = state.userLikedStatus.attributes.liked
          ? state.likesCount + 1
          : state.likesCount - 1;
      })
      .addCase(updateLikedStatus.rejected, (state, action) => {
        state.updatingLikeStatus = false;
        state.errorMessage = action.error.message;
      });
  },
});
export default slice.reducer;

// Actions
export const {
  resetSearch,
  selectTag,
  deSelectTag,
  resetTagSelection,
  doSearch,
  doResetSearch,
} = slice.actions;
