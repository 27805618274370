import { useEffect, useState } from "react";

const useCopyLink = () => {
  const [copiedLink, setCopiedLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (copiedLink) {
      if (typeof navigator !== "undefined") {
        console.log(`Link copied ${copiedLink}`);
        navigator.clipboard.writeText(copiedLink);
        setIsCopied(true);
        setTimeout(() => {
          setCopiedLink("");
          setIsCopied(false);
        }, 5000);
      }
    }
  }, [copiedLink]);

  const copyLink = (link) => {
    setCopiedLink(link);
  };

  return [isCopied, copiedLink, copyLink];
};

export default useCopyLink;
