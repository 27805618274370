import React from "react";
import googleIcon from "../../resources/google.svg";

const GoogleSignIn = () => {
  return (
    <button class="gsi-material-button">
      <div class="gsi-material-button-state"></div>
      <div class="gsi-material-button-content-wrapper">
        <div class="gsi-material-button-icon">
          <img src={googleIcon} style={{ width: "20px", height: "20px" }} alt="Google Icon" />
        </div>
        <span class="gsi-material-button-contents">Sign in</span>
        <span style={{ display: "none" }}>Sign in with Google</span>
      </div>
    </button>
  );
};

export default GoogleSignIn;
