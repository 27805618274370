import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { getPinById } from "../../store/pin";
import Pin from "../../components/Pin/Pin";
import Loading from "../../components/common/Loading";

import useCopyLink from "../../hooks/useCopyLink";

import backArrowIcon from "../../resources/backarrow.svg";

function PinView() {
  const { id } = useParams();
  const { isLoading, pinDetails } = useSelector((s) => s.pin);
  const dispatch = useDispatch();
  const [isCopied, copiedLink, copyLink] = useCopyLink();

  useEffect(() => {
    if (id) {
      dispatch(getPinById(id));
    }
  }, [dispatch, id]);

  console.log("PinView>>>", pinDetails);

  return (
    <>
      {isLoading && <Loading text="Loading pin" />}
      {!isLoading && pinDetails && (
        <div style={{ marginBottom: "1.5rem", marginTop: "1rem" }}>
          <Link to="/" style={{ color: "#CACACA", textDecoration: "none" }}>
            <img
              src={backArrowIcon}
              alt="back"
              style={{ marginTop: "-2px", marginRight: "0.5rem" }}
            />{" "}
            See all pins
          </Link>
        </div>
      )}
      {!isLoading && pinDetails && (
        <Pin
          pin={pinDetails}
          teaser={true}
          isCopied={isCopied}
          copiedLink={copiedLink}
          copyLink={copyLink}
        />
      )}
    </>
  );
}

export default PinView;
