import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./page.module.css";

const Page = ({ title, text }) => {
  return (
    <>
      {title && (
        <>
          {title && (
            <Row>
              <Col>
                <h1 className={styles.title}>{title}</h1>
              </Col>
            </Row>
          )}
          <Row>
            <Col className={styles.text}>{text}</Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Page;
