import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, NavLink, useLocation } from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./layout.module.css";
import pinIcon from "../../resources/pin.svg";
import authorIcon from "../../resources/author.svg";
import { ReactComponent as ScrollToTopIcon } from "../../resources/scrollToTop.svg";

import NavBar from "./NavBar";
import TagFilter from "../TagFilter/TagFilter";

import {
  getTags,
  selectTag,
  deSelectTag,
  resetTagSelection,
  getSiteLikes,
} from "../../store/pin";

const Layout = () => {
  let location = useLocation();
  // console.log("location.pathname ////", location);

  const { tags, tagsSelected } = useSelector((s) => s.pin);
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(true);
  const [pageWithouSidebar, setPageWithouSidebar] = useState(false);
  const [pageClassName, setPageClassName] = useState("page-home");
  const [pageScrollOnTop, setPageScrollOnTop] = useState(true);

  useEffect(() => {
    dispatch(getTags());
    dispatch(getSiteLikes());
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname.startsWith("/author")) {
      setShowFilter(false);
      setPageClassName("page-author");
    } else if (location.pathname.startsWith("/pin/")) {
      setShowFilter(false);
      setPageClassName("page-pin");
    } else if (location.pathname.startsWith("/privacy-policy")) {
      setPageWithouSidebar(true);
      setPageClassName("page-privacy-policy");
    } else {
      setShowFilter(true);
      setPageWithouSidebar(false);
      setPageClassName("page-home");
    }
  }, [location.pathname]);

  const onSelectTag = (tagId) => {
    dispatch(selectTag(tagId));
  };

  const onDeSelectTag = (tagId) => {
    dispatch(deSelectTag(tagId));
  };

  const onClearTagSelection = () => {
    dispatch(resetTagSelection());
  };

  const handleScroll = () => {
    const position = window.scrollY;
    if (position <= 5) {
      setPageScrollOnTop(true);
    } else {
      setPageScrollOnTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("Tags>>>", tags);

  return (
    <div className={pageClassName}>
      <div className={`${styles.navbarWrapper} navbarWrap`}>
        <Container className="outerContainer">
          <NavBar />
        </Container>
      </div>
      <Container
        style={{ marginTop: "20px", paddingBottom: "10rem" }}
        className="outerContainer"
      >
        <Row>
          <Col
            xs={{ order: "last" }}
            md={{ span: 3, order: "first" }}
            style={{ display: pageWithouSidebar ? "none" : "block" }}
          >
            <div className={`${styles.menuNav} menu-navigation`}>
              <NavLink
                to="/"
                className={`${styles.navLink} ${styles.navLinkActive} navitem`}
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
              >
                <img src={pinIcon} alt="pin" className={styles.pinIconMenu} />{" "}
                <span>Pins</span>
              </NavLink>
              <NavLink to="/author" className={`${styles.navLink} navitem`}>
                <img
                  src={authorIcon}
                  alt="pin"
                  className={styles.authorIconMenu}
                />{" "}
                Author
              </NavLink>
            </div>
          </Col>
          <Col md={pageWithouSidebar ? 12 : 6}>
            <main>
              <Outlet />
            </main>
          </Col>
          <Col
            xs={{ order: "first" }}
            md={{ span: 3, order: "last" }}
            style={{ display: pageWithouSidebar ? "none" : "block" }}
          >
            {showFilter && (
              <TagFilter
                tags={tags}
                tagsSelected={tagsSelected}
                onSelectTag={onSelectTag}
                onDeSelectTag={onDeSelectTag}
                onClearTagSelection={onClearTagSelection}
              />
            )}
          </Col>
        </Row>
        <ScrollToTop smooth component={<ScrollToTopIcon />} />
      </Container>
      <div
        className={`${styles.privacyLink} privacy-link ${
          pageScrollOnTop ? "on-top" : ""
        }`}
      >
        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
      </div>
    </div>
  );
};

export default Layout;
