import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";

import Popover from "react-bootstrap/Popover";
import GoogleSignIn from "./GoogleSignIn";
import Like from "./Like";

import useGLogin from "../../hooks/useGLogin";
import {
  getUserLikeStatus,
  createLikedStatus,
  updateLikedStatus,
} from "../../store/pin";

import styles from "./navRight.module.css";

import searchIcon from "../../resources/searchIcon.svg";

const NavRightItems = ({ setShowFixedSearch }) => {
  const [showLikeTooltip, setShowLikeTooltip] = useState(false);
  const { isLoggedIn, name, email, setLoginData, resetLoginData } = useGLogin();
  const { likesCount, userLikedStatus, updatingLikeStatus } = useSelector(
    (s) => s.pin
  );

  const dispatch = useDispatch();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {!isLoggedIn
        ? "Please sign in to like this"
        : "Click to like this website"}
    </Tooltip>
  );

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log({ tokenResponse });
      const accessToken = tokenResponse.access_token;
      if (!isLoggedIn && accessToken) {
        const { data } = await axios.get(
          "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (data.email) {
          setLoginData(data.given_name, data.email);
        }
      }
    },
  });

  const logout = () => {
    googleLogout();
    resetLoginData();
  };

  const onLikeClick = () => {
    if (isLoggedIn && !updatingLikeStatus) {
      if (userLikedStatus && userLikedStatus.id) {
        const currentStatus = userLikedStatus.attributes.liked;
        dispatch(
          updateLikedStatus({ id: userLikedStatus.id, status: !currentStatus })
        );
      } else {
        dispatch(createLikedStatus({ status: true, email }));
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn && email) {
      dispatch(getUserLikeStatus(email));
    }
  }, [dispatch, isLoggedIn, email]);

  return (
    <div className={styles.navRightItems}>
      {!isLoggedIn && (
        <Nav.Link
          eventKey={2}
          href="#signin"
          className={`${styles.navRightItemsLink} ${styles.signInMenu}`}
          onClick={() => login()}
        >
          <Row className={styles.signIn}>
            <Col xs={12}>
              <GoogleSignIn />
            </Col>
          </Row>
        </Nav.Link>
      )}
      {isLoggedIn && (
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom"
          overlay={
            <Popover id="popover-basic" style={{ cursor: "pointer" }}>
              <Popover.Body onClick={(e) => logout()}>Logout</Popover.Body>
            </Popover>
          }
        >
          <Nav.Link
            eventKey={2}
            className={`${styles.navRightItemsLink} ${styles.loginName}`}
          >
            <span>{name && name.charAt(0).toLowerCase()}</span>
          </Nav.Link>
        </OverlayTrigger>
      )}

      <OverlayTrigger
        placement="bottom"
        delay={{ show: 150, hide: 250 }}
        overlay={renderTooltip()}
        trigger="hover"
        rootClose
        onToggle={(show) => {
          console.log({ show });
          setShowLikeTooltip(show);
        }}
        show={showLikeTooltip}
      >
        <Nav.Link
          eventKey={2}
          href="#likes"
          className={`${styles.navRightItemsLink} ${styles.siteLikeLink}`}
          onClick={() => {
            if (!isLoggedIn) {
              login();
            } else {
              onLikeClick();
            }
          }}
        >
          <Like
            isActive={
              isLoggedIn && userLikedStatus && userLikedStatus.attributes.liked
            }
            likesCount={likesCount}
          />
        </Nav.Link>
      </OverlayTrigger>

      <a
        href="#search"
        alt="search"
        className={`${styles.navRightItemsLink} ${styles.searchIcon}`}
        onClick={() => setShowFixedSearch(true)}
      >
        <img src={searchIcon} alt="Like" className={styles.siteLikeIcon} />
      </a>
    </div>
  );
};

export default NavRightItems;
