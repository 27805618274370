import { useState } from "react";

const useGLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") || false
  );
  const [name, setName] = useState(localStorage.getItem("g_name") || "");
  const [email, setEmail] = useState(localStorage.getItem("g_email") || "");

  const setLoginData = (name, email) => {
    setName(name);
    setEmail(email);
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("g_name", name);
    localStorage.setItem("g_email", email);
  };

  const resetLoginData = () => {
    localStorage.setItem("isLoggedIn", false);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("g_name");
    localStorage.removeItem("g_email");
    setName("");
    setEmail("");
    setIsLoggedIn(false);
  };

  return { isLoggedIn, name, email, setLoginData, resetLoginData };
};

export default useGLogin;
