import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

import styles from "./loadmore.module.css";

const LoadMore = ({ onClickTigger, isLoading, pagination }) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const tirggerClick = () => {
    onClickTigger(pagination?.page + 1);
  };

  useEffect(() => {
    console.log("inview", inView, entry);
    if (inView && pagination) {
      tirggerClick();
    }
    /* eslint-disable-next-line */
  }, [inView, entry]);

  return (
    <div className={styles.loadMoreWrapper}>
      {!isLoading && pagination?.page < pagination?.pageCount && (
        <Button
          variant="link"
          className={styles.loadMore}
          onClick={() => {
            tirggerClick();
          }}
          ref={ref}
        >
          Load more items
        </Button>
      )}

      {isLoading && <span className={styles.loading}>Loading...</span>}
    </div>
  );
};

export default LoadMore;
