import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const Loading = ({ text = "Loading pins" }) => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div style={{ textAlign: "center", margin: "5rem" }}>
            <Spinner animation="grow" />
            <div style={{ color: "#898989" }}>{text}</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Loading;
