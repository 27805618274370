import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRemark } from "react-remark";
import { Link } from "react-router-dom";

import { getPrivacyPolicy } from "../../store/pin";
import Loading from "../../components/common/Loading";

import Page from "../../components/Page";
import backArrowIcon from "../../resources/backarrow.svg";

function PrivacyPolicy() {
  const { privacyPolicy, isLoading } = useSelector((s) => s.pin);
  const dispatch = useDispatch();

  const [markupContent, setMarkdownSource] = useRemark({
    rehypeReactOptions: {
      components: {
        /* eslint-disable-next-line */
        a: (props) => <a target="_blank" {...props} />,
      },
    },
  });

  useEffect(() => {
    if (privacyPolicy?.attributes?.privacyText) {
      setMarkdownSource(privacyPolicy.attributes.privacyText);
    }
    /* eslint-disable-next-line */
  }, [privacyPolicy?.attributes?.privacyText]);

  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, [dispatch]);

  console.log("Privacy Policy>>>", privacyPolicy);

  return (
    <>
      {isLoading && <Loading text="Loading" />}
      {!isLoading && markupContent && (
        <div style={{ marginBottom: "1.5rem", marginTop: "1rem" }}>
          <Link to="/" style={{ color: "#CACACA", textDecoration: "none" }}>
            <img
              src={backArrowIcon}
              alt="back"
              style={{ marginTop: "-2px", marginRight: "0.5rem" }}
            />{" "}
            Back to pins
          </Link>
        </div>
      )}
      {!isLoading && markupContent && (
        <Page
          title={privacyPolicy?.attributes?.title}
          text={markupContent}
        />
      )}{" "}
    </>
  );
}

export default PrivacyPolicy;
