import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import NavRightItems from "./NavRightItems";

import styles from "./layout.module.css";

import pinIcon from "../../resources/pin.svg";
import logoText from "../../resources/logoText.svg";
// import likeIcon from "../../resources/like.svg";
// import likeIconActive from "../../resources/likeActive.svg";
// import profileIcon from "../../resources/profile.svg";
// import searchIcon from "../../resources/searchIcon.svg";

import { doSearch, doResetSearch } from "../../store/pin";

const NavBar = () => {
  let location = useLocation();
  console.log("location.pathname ////", location.pathname);
  let navigate = useNavigate();

  const { tags, searchText } = useSelector((s) => s.pin);
  const dispatch = useDispatch();
  const [showFixedSearch, setShowFixedSearch] = useState(false);
  const [showLikeTooltip, setShowLikeTooltip] = useState(false);

  console.log("Tags>>>", tags);

  useEffect(() => {
    if (showLikeTooltip) {
      setTimeout(() => {
        setShowLikeTooltip(false);
      }, 2000);
    }
  }, [showLikeTooltip]);

  return (
    <>
      <div className={styles.navbar}>
        <NavLink
          to="/"
          className={styles.logoNav}
          onClick={() => {
            window.scrollTo({ top: 0 });
          }}
        >
          {" "}
          <img
            src={logoText}
            alt="Marketing Pins"
            className={styles.logoText}
          />{" "}
          <img src={pinIcon} alt="pin" />
        </NavLink>
        <Form
          className={`${styles.searchWrapper} ${
            showFixedSearch ? styles.searchFixedOnMobile : null
          }`}
          onSubmit={(e) => {
            e.preventDefault();
            console.log(e.target.elements.searchText.value);
            if (e.target.elements.searchText.value) {
              setShowFixedSearch(false);
              dispatch(doSearch(e.target.elements.searchText.value));
              navigate("/");
            } else {
              setShowFixedSearch(false);
              dispatch(doResetSearch());
              navigate("/");
            }
          }}
        >
          <Form.Control
            id="search-input-text"
            type="search"
            name="searchText"
            placeholder="Search pins..."
            className={styles.searchInput}
            aria-label="Search pins..."
            defaultValue={searchText}
            // onChange={(e) => {
            //   if (e.target?.form[0].value === "") {
            //     e.target.form[1].style.visibility = "hidden";
            //   } else {
            //     e.target.form[1].style.visibility = "visible";
            //   }
            // }}
          />
          <button
            type="button"
            className={styles.btnClose}
            onClick={(e) => {
              console.log(e);
              if (e.target?.form[0]) {
                e.target.form[0].value = "";
              }
              setShowFixedSearch(false);
              if (searchText) {
                dispatch(doResetSearch());
                navigate("/");
              }
            }}
          ></button>
        </Form>
        <NavRightItems setShowFixedSearch={setShowFixedSearch} />
      </div>
    </>
  );
};

export default NavBar;
