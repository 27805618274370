import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import pin from "./pin";

const reducer = combineReducers({
  pin,
});
const store = configureStore({
  reducer,
});

export default store;
