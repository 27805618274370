import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import useCopyLink from "../../hooks/useCopyLink";

import { getPins, doResetSearch } from "../../store/pin";
import Pin from "../../components/Pin/Pin";
import LoadMore from "../../components/common/LoadMore";
import Modal from "react-bootstrap/Modal";
import Loading from "../../components/common/Loading";

import searchResultIcon from "../../resources/searchResult.svg";

import styles from "./home.module.css";

function Home() {
  const {
    pins,
    pinsPagination,
    pinDetails,
    isLoading,
    tagsSelected,
    searchText,
  } = useSelector((s) => s.pin);
  const dispatch = useDispatch();
  const [isCopied, copiedLink, copyLink] = useCopyLink();

  const [showPinViewModal, setShowPinViewModal] = useState(false);

  useEffect(() => {
    if ((!pins || !pins.length) && pinsPagination == null) {
      dispatch(
        getPins({
          page: pinsPagination?.page,
          tags: tagsSelected,
          searchText,
        })
      );
    }
  }, [dispatch, pins, pinsPagination, tagsSelected, searchText]);

  // useEffect(() => {
  //   if (searchTerm && !isLoading) {
  //     console.log("############################", searchTerm);
  //     dispatch(doSearch(searchTerm));
  //   }
  // }, [dispatch, searchTerm, isLoading]);

  const loadMore = (page) => {
    dispatch(getPins({ page, tags: tagsSelected, searchText }));
  };

  console.log("Home>>>", pins);

  return (
    <>
      {pins && searchText && pinsPagination && pinsPagination.total > 0 && (
        <div className={styles.resultCountMsg}>
          {pinsPagination.total} Results found
        </div>
      )}
      {pins &&
        pins.map((pin) => (
          <Pin
            pin={pin}
            teaser={true}
            tagsSelected={tagsSelected}
            isCopied={isCopied}
            copiedLink={copiedLink}
            copyLink={copyLink}
            searchText={searchText}
          />
        ))}
      {pins.length === 0 &&
        pinsPagination !== null &&
        pinsPagination.total === 0 && (
          <p className={styles.emptySearchResult}>
            <>
              <img
                src={searchResultIcon}
                alt="search no result"
                className={styles.searchResultIcon}
              />

              {searchText !== "" ? (
                <>
                  Sorry, no pins related to '{searchText}' were found. Please
                  try a different keyword.
                </>
              ) : (
                <>
                  Sorry, no matching pins found for the filters applied! Please
                  do refine your filters.
                </>
              )}
              <NavLink
                to="/"
                onClick={() => {
                  dispatch(doResetSearch());
                  window.scrollTo({ top: 0 });
                }}
                className={styles.seeAllPins}
              >
                See all pins
              </NavLink>
            </>
          </p>
        )}
      {isLoading && <Loading />}
      {pins && (
        <LoadMore
          onClickTigger={loadMore}
          isLoading={isLoading}
          pagination={pinsPagination}
        />
      )}
      {pinDetails && !isLoading && (
        <Modal
          show={showPinViewModal}
          onHide={() => setShowPinViewModal(false)}
          dialogClassName="modal-750w"
        >
          <Modal.Body>
            <Pin pin={pinDetails} teaser={false} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default Home;
