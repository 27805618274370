import React, { useState, useEffect } from "react";
import { useRemark } from "react-remark";
import Lightbox from "react-awesome-lightbox";

import Carousel from "react-bootstrap/Carousel";
import { getBaseUrl } from "../../utils";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import copyIcon from "../../resources/copy.svg";
import pinIcon from "../../resources/pin.svg";

import styles from "./pin.module.css";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DateFromat = ({ dateString }) => {
  const date = new Date(dateString);
  return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
};

const Tags = ({ tags, tagsSelected }) => {
  console.log({ tags, tagsSelected });
  return (
    <div className={styles.tagWrapper}>
      {tags &&
        tags.map((tag) => (
          <Badge
            title={tag?.attributes?.name}
            bg="dark"
            className={`${styles.tag} ${
              tagsSelected && tagsSelected.find((t) => t === tag.id)
                ? styles.tagActive
                : ""
            } ${
              tags.length > 2
                ? styles.tagMedium
                : tags.length === 2
                ? styles.tagLarge
                : null
            }`}
          >
            {tag?.attributes?.name}
          </Badge>
        ))}
    </div>
  );
};

const getHighlightedText = (text, highlight) => {
  // Split on highlight term and include term into parts, ignore case
  // if (!highlight.trim()) {
  return text;
  // }
  // TODO: IT IS COMMENTED BECAUSE IT REPLACE LINK URLS ALSO
  // const regex = new RegExp(`(${highlight})`, "gi");
  // const parts = text.split(regex);
  // const partsAltered = parts.map((part) => {
  //   if (part.toLowerCase() === highlight.toLowerCase()) {
  //     return `\`\`\`${part}\`\`\``;
  //   }
  //   return part;
  // });
  // return partsAltered.join("");
};

const Pin = ({
  pin,
  teaser,
  tagsSelected,
  isCopied,
  copiedLink,
  copyLink,
  searchText,
}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [teaseFlag, setTeaserFlag] = useState(teaser);
  const [markupContent, setMarkdownSource] = useRemark({
    rehypeReactOptions: {
      components: {
        /* eslint-disable-next-line */
        a: (props) => <a target="_blank" {...props} />,
      },
    },
  });
  const [markupContentFull, setMarkdownSourceFull] = useRemark({
    rehypeReactOptions: {
      components: {
        /* eslint-disable-next-line */
        a: (props) => <a target="_blank" {...props} />,
      },
    },
  });

  useEffect(() => {
    if (pin.attributes.pintext) {
      setMarkdownSource(
        getHighlightedText(
          teaseFlag && pin.attributes.pintextFull
            ? `${pin.attributes.pintext} [Read more](#mpReadMore)`
            : pin.attributes.pintext,
          searchText
        )
      );
    }
    /* eslint-disable-next-line */
  }, [pin.attributes.pintext]);

  useEffect(() => {
    if (pin.attributes.pintextFull) {
      setMarkdownSourceFull(
        getHighlightedText(pin.attributes.pintextFull, searchText)
      );
    }
    /* eslint-disable-next-line */
  }, [pin.attributes.pintextFull]);

  const pinUrl = `${getBaseUrl()}/pin/${pin.id}`;

  console.log({ pin });
  return (
    <div
      className={
        teaseFlag
          ? `${styles.teaserView} mrkPinTeaserView`
          : `${styles.fullView} mrkPinFullView`
      }
    >
      <Row key={pin.id}>
        <Col xs={7} md={8} className="col-first">
          <img
            src={pinIcon}
            alt="pin"
            style={{ marginRight: "8px", marginTop: "-3px" }}
          />
          <span className={styles.dimText}>{pin.id}</span>
        </Col>
        <Col
          xs={5}
          md={4}
          style={{ textAlign: "right" }}
          className={`${styles.dimText} col-last`}
        >
          <DateFromat dateString={pin.attributes.createdAt} />
        </Col>
      </Row>
      <Row>
        <Col className="col-first">
          <div className={`${styles.content} mrkPinContentonModal`}>
            {teaseFlag && pin.attributes.pintextFull && (
              <div
                onClick={(e) => {
                  console.log(e.target.dataset.type);
                  // trigger function only when readmore link clicked
                  if (e.target?.href?.indexOf("#mpReadMore") >= 0) {
                    e.preventDefault();
                    setTeaserFlag(false);
                    return;
                  }
                }}
              >
                {markupContent}
              </div>
            )}
            {((teaseFlag && !pin.attributes.pintextFull) ||
              (!teaseFlag && !pin.attributes.pintextFull)) && (
              <>{markupContent}</>
            )}
            {!teaseFlag && pin.attributes.pintextFull && (
              <>{markupContentFull}</>
            )}
          </div>
          {pin?.attributes?.image?.data?.length === 1 && (
            <div className={styles.trimImage}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}${pin?.attributes?.image?.data[0].attributes.url}`}
                alt={pin?.attributes?.image?.data[0].attributes.name}
                className={styles.image}
                style={{ cursor: "pointer" }}
                onClick={() => setShowImageModal(true)}
              />
            </div>
          )}
          {pin?.attributes?.image?.data?.length > 1 && (
            <Carousel interval={null}>
              {pin?.attributes?.image?.data.map((slide) => {
                return (
                  <Carousel.Item>
                    <img
                      className={styles.image}
                      src={`${process.env.REACT_APP_API_BASE_URL}${pin?.attributes?.image?.data[0].attributes.url}`}
                      alt={slide.attributes?.name}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={8} md={8} className="col-first">
          <Tags tags={pin.attributes?.tags?.data} tagsSelected={tagsSelected} />
        </Col>
        <Col xs={4} md={4} style={{ textAlign: "right" }} className="col-last">
          <div onClick={() => copyLink(pinUrl)} className={styles.copyLink}>
            {isCopied && copiedLink === pinUrl ? (
              <>
                <img src={copyIcon} alt="Tick" /> Copied!
              </>
            ) : (
              <>
                <img src={copyIcon} alt="Copy" /> Copy link
              </>
            )}
          </div>
        </Col>
      </Row>
      {pin?.attributes?.image?.data?.length === 1 && showImageModal && (
        <Lightbox
          image={`${process.env.REACT_APP_API_BASE_URL}${pin?.attributes?.image?.data[0].attributes.url}`}
          onClose={() => setShowImageModal(false)}
          allowRotate={false}
          doubleClickZoom={false}
        />
      )}
    </div>
  );
};

export default Pin;
