import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getAuthorProfile } from "../../store/pin";
import Loading from "../../components/common/Loading";

import AuthorProfile from "../../components/AuthorProfile";

function Author() {
  const { authorProfile, isLoading } = useSelector((s) => s.pin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuthorProfile());
  }, [dispatch]);

  console.log("Author>>>", authorProfile);

  return (
    <>
      {isLoading && <Loading text="Loading" />}
      {!isLoading && authorProfile && (
        <AuthorProfile authorProfile={authorProfile} />
      )}{" "}
    </>
  );
}

export default Author;
