import React from "react";
import Form from "react-bootstrap/Form";
import { Scrollbar } from "react-scrollbars-custom";

import styles from "./tagFilter.module.css";

const Filter = ({
  tags,
  tagsSelected,
  onSelectTag,
  onDeSelectTag,
  onClearTagSelection,
  isOpen,
  searchText,
  setSearchText,
}) => {
  return (
    <>
      {isOpen && (
        <div>
          <Form.Control
            type="search"
            placeholder="Search tags"
            className={styles.searchTagInput}
            aria-label="Search tags"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div className={styles.apldFilters}>
            <h2>
              Applied filters{" "}
              {tagsSelected &&
                tagsSelected.length > 0 &&
                `(${tagsSelected.length})`}
              {tagsSelected && tagsSelected.length > 0 && (
                <a
                  href="#d"
                  title="clear all"
                  onClick={() => onClearTagSelection()}
                >
                  Clear all
                </a>
              )}
            </h2>
            <Scrollbar style={{ height: 390 }} noScrollX>
              <div className={styles.apldFiltersListWrap}>
                {tagsSelected && tagsSelected.length > 0 && (
                  <ul>
                    {tags
                      .filter((tag) => tagsSelected.includes(tag.id))
                      .filter(
                        (tag) =>
                          tag.attributes.name
                            .toLowerCase()
                            .indexOf(searchText.toLowerCase()) !== -1
                      )
                      .map((tag) => (
                        <li key={`tagcheckbox-${tag.id}`}>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`tags-${tag.id}`}
                            label={tag.attributes.name}
                            onClick={() => {
                              onDeSelectTag(tag.id);
                            }}
                            checked
                          />
                        </li>
                      ))}
                  </ul>
                )}
                {!tagsSelected ||
                  (tagsSelected.length === 0 && <p>No tags applied</p>)}
                <h2>Select tags</h2>
                {tags && tags.length > 0 && (
                  <ul>
                    {tags
                      .filter((tag) => !tagsSelected.includes(tag.id))
                      .filter(
                        (tag) =>
                          tag.attributes.name
                            .toLowerCase()
                            .indexOf(searchText.toLowerCase()) !== -1
                      )
                      .map((tag) => (
                        <li key={`tagcheckbox-${tag.id}`}>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`tags-${tag.id}`}
                            label={tag.attributes.name}
                            onClick={() => {
                              onSelectTag(tag.id);
                            }}
                          />
                        </li>
                      ))}
                  </ul>
                )}
                {tags &&
                  tags.length > 0 &&
                  tags.filter((tag) => !tagsSelected.includes(tag.id))
                    .length === 0 && <p>All tags selected</p>}
              </div>
            </Scrollbar>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;
