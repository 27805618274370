import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

import filterIcon from "../../resources/filter.svg";
import arrowUp from "../../resources/arrowUp.svg";
import arrowDown from "../../resources/arrowDown.svg";

import styles from "./tagFilter.module.css";
import Filter from "./Filter";

import backArrowIcon from "../../resources/backarrow.svg";

const TagFilter = ({
  tags,
  tagsSelected,
  onSelectTag,
  onDeSelectTag,
  onClearTagSelection,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  return (
    <div className={styles.tagFilterWrapper}>
      <div
        className={
          !isOpen
            ? styles.filterByTagsTitle
            : `${styles.filterByTagsTitle} ${styles.filterByTagsTitleOpen}`
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={filterIcon} alt="filter" className={styles.filterIcon} />
        Filter by tags 
        {tagsSelected && tagsSelected.length > 0 && (
          <span className={styles.tagsCount}>
            {tagsSelected.length} {tagsSelected.length > 1 ? "tags" : "tag"}
          </span>
        )}
        <img
          src={isOpen ? arrowUp : arrowDown}
          alt="arrow down"
          className={styles.arrowIcon}
        />
      </div>

      {!isOpen && tagsSelected && tagsSelected.length > 0 && (
        <div className={styles.filtersApplied}>
          Filters applied{" "}
          <span>
            {tagsSelected.length} {tagsSelected.length > 1 ? "tags" : "tag"}
          </span>
        </div>
      )}

      <div className={styles.showOnTabAbove}>
        <Filter
          tags={tags}
          tagsSelected={tagsSelected}
          onSelectTag={onSelectTag}
          onDeSelectTag={onDeSelectTag}
          onClearTagSelection={onClearTagSelection}
          isOpen={isOpen}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </div>

      <div className={styles.showOnMobile}>
        <Offcanvas
          show={isOpen}
          onHide={() => setIsOpen(false)}
          placement="end"
          className={styles.filterOffcanvas}
          scroll={true}
        >
          <Offcanvas.Header
            className={styles.filterOffcanvasHd}
            closeButton
            onClick={() => setIsOpen(false)}
          >
            <Offcanvas.Title>
              <img
                src={backArrowIcon}
                alt="back"
                style={{ marginTop: "-2px", marginRight: "0.5rem" }}
              />{" "}
              Filter by tags
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filter
              tags={tags}
              tagsSelected={tagsSelected}
              onSelectTag={onSelectTag}
              onDeSelectTag={onDeSelectTag}
              onClearTagSelection={onClearTagSelection}
              isOpen={isOpen}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default TagFilter;
