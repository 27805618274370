import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import styles from "./author.module.css";
import authourBanner from "../../resources/authorbanner.png";
import linkedInIcon from "../../resources/linkedin.svg";
import downloadIcon from "../../resources/download.svg";

const AuthorProfile = ({ authorProfile }) => {
  return (
    <>
      {authorProfile && (
        <>
          {authorProfile?.attributes?.profilePicture?.data?.attributes?.url && (
            <Row>
              <Col>
                <div className={styles.banner}>
                  <img
                    src={authourBanner}
                    alt="banner"
                    className={styles.bannerBg}
                  />
                  <Image
                    src={`${process.env.REACT_APP_API_BASE_URL}${authorProfile?.attributes?.profilePicture?.data?.attributes?.url}`}
                    roundedCircle
                    className={styles.profilePic}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <h2 className={styles.name}>{authorProfile?.attributes?.name}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className={styles.title}>
                {authorProfile?.attributes?.headLine}
              </h3>
              {authorProfile?.attributes?.linkedin && (
                <div className={styles.linkedIn}>
                  <a
                    href={authorProfile?.attributes?.linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedInIcon} alt="linked in" />
                  </a>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={styles.coverLetter}>
                {authorProfile?.attributes?.coverLetter}
              </p>
            </Col>
          </Row>

          <Row>
            <Col style={{ textAlign: "center" }}>
              {authorProfile?.attributes?.resume?.data?.attributes?.url && (
                <a
                  href={`${process.env.REACT_APP_API_BASE_URL}${authorProfile?.attributes?.resume?.data?.attributes?.url}`}
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Resume
                  <img src={downloadIcon} alt="Download resume" />
                </a>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AuthorProfile;
